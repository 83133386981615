import { job_board } from "../services/api";

export const NewsletterSubscribe = function () {
	return {
		payload: {
			first_name: "",
			email: "",
			list_ids: [],
		},
		isLoading: false,
		status: "",
		displayMessage: "",
		buttonText: "Submit",

		onError(error = "") {
			this.status = "error";
			this.displayMessage =
				error ||
				`There was an error subscribing you to the newsletter.`;
		},

		onSuccess() {
			this.status = "success";
			this.displayMessage = "Thank you for subscribing!";
			this.clearForm();
		},

		clearForm() {
			this.payload = {
				first_name: "",
				email: "",
				list_ids: [],
			};
		},

		async onSubmit() {
			this.isLoading = true;
			this.buttonText = "Submitting...";
			this.displayMessage = "";

			try {
				const req = await job_board.post(
					"/newsletter-subscribe",
					this.payload
				);
				const res = req.data;
				// check if status code is 200
				if (req.status === 200) {
					this.onSuccess();
					if (res?.status && res?.status === 400) {
						console.log({ res });
						this.onError(res?.data);
					}
				} else {
					this.onError(res?.data);
				}
			} catch (error) {
				this.onError();
				console.log({ error });
			}

			this.isLoading = false;
			this.buttonText = "Submit";
		},
	};
};
