import "../scss/frontend.scss";

import Alpine from "alpinejs";
import { NewsletterSubscribe } from "./components/NewsletterSubscribe";
import PostFilter from "./components/PostFilter";
import { ManageJobs } from "./components/ManageJobs";
import { EditJobs } from "./components/EditJobs";

declare global {
	interface Window {
		Alpine: any;
		global_vars: any;
	}
}

Alpine.data("NewsletterSubscribe", NewsletterSubscribe);

Alpine.data("ManageJobs", ManageJobs);

Alpine.data("EditJobs", EditJobs);

Alpine.prefix("jb-");

Alpine.start();

window.addEventListener('DOMContentLoaded', function() {
	PostFilter();
});