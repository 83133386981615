import { job_board } from "../services/api";

export const EditJobs = function () {
	return {
		loading: false,
		message: "",

		init(e) {
			let editJobForms = document.querySelectorAll(".edit-job__form");
			editJobForms?.forEach((editJobForm) => {
				this.addListeners(editJobForm);
			});
		},

		addListeners(formContainer : HTMLElement) {
			const form = formContainer.querySelector("form") as HTMLFormElement;

			form.addEventListener("click", (e) => {
				const target = e.target as HTMLElement;
				const jobID = formContainer?.dataset?.id;
				const metaKey = target?.dataset?.metaKey;
				const attachment = target?.dataset?.attachment;

				if (target.classList.contains("edit-job__delete-attachment")) {
					this.deleteFile(jobID, metaKey, attachment, target);
				}
			});

			form.addEventListener("change", (e) => {						
				const target = e.target as HTMLFormElement
				if (target.nodeName === "INPUT" && target.type === "file") {
					const file = target.files[0];
					if (file) {
						const field = target?.closest(".gfield");
						const sibling = field?.previousElementSibling;
						const hasFilePreview = sibling?.classList?.contains("gfield--type-filepreview");
						if (hasFilePreview) {
							sibling?.remove();
						}
					}
				}
			});
		},

		async deleteFile(jobID: string, metaKey: string, attachment: string, target: HTMLElement) { 
			if (confirm("Are you sure you want to delete this file?")) {
				try {
					const response = await job_board.delete(`/attachment/${jobID}/delete/`, { data: { metaKey, attachment } });
					if (response.status === 200) {
						const gfield = target.closest(".gfield");
						gfield && gfield.remove();
					} else {
						throw new Error("Failed to delete attachment.");
					}
				} catch (error) {
					console.error("Error deleting attachment:", error);
				}
			}
		},
	};
};
