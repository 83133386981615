import { job_board } from "../services/api";

export const ManageJobs = function () {
	return {
		loading: false,
		message: "",

		init(e) {
			let jobBoardTables = document.querySelectorAll(".job-board__table");
			jobBoardTables?.forEach((jobBoardTable) => {
				this.addListeners(jobBoardTable);
			});
		},

		addListeners(jobBoardTable : Element) {
			jobBoardTable.addEventListener("click", (e) => {
				const target = e.target as HTMLElement;
				const listing_status = target.dataset.listing_status;
				const job_status = target.dataset.job_status;
				const remove = target.dataset.remove;
				const id = target.closest("tr")?.dataset?.job_id;

				if (listing_status) {
					if (id) {
						this.updateJobListingStatus(id, listing_status);
					}
				}

				if (job_status) {
					if (id) {
						this.updateJobStatus(id, job_status);
					}
				}

				if (remove) {
					if (id) {
						this.deleteJob(id, job_status);
					}
				}
			});
		},

		async updateJobListingStatus(id: string, status: string) {
			try {
				const response = await job_board.post(
					`/manage/${id}/updateJobListingStatus`,
					{
						status,
					}
				);
				if (response.status === 200) {
					location.reload();
				} else {
					throw new Error(
						`Failed to update post status ${response.data.message}`
					);
				}
			} catch (error: any) {
				console.error(
					"Error updating post status:",
					error.response?.data?.message
				);
			}
		},

		async updateJobStatus(id: string, status: string) {
			try {
				const response = await job_board.post(
					`/manage/${id}/updateJobStatus`,
					{
						status,
					}
				);
				if (response.status === 200) {
					location.reload();
				} else {
					throw new Error(
						`Failed to update job status ${response.data.message}`
					);
				}
			} catch (error: any) {
				console.error(
					"Error updating job status:",
					error.response?.data?.message
				);
			}
		},

		async deleteJob(id: string) {
			if (confirm("Are you sure you want to delete this job?")) {
				try {
					const response = await job_board.delete(
						`/manage/${id}/delete`
					);
					if (response.status === 200) {
						console.log("Job deleted successfully");
						const row = document.querySelector(`#job-${id}`);
						row && row.remove();
					} else {
						throw new Error("Failed to delete job");
					}
				} catch (error) {
					console.error("Error deleting job:", error);
				}
			}
		},
	}
};
