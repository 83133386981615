import axios from "axios";

import { TOKEN } from "./token";

const instance = ({
	baseURL = "",
	headers = { "X-WP-Nonce": TOKEN || "" },
} = {}) =>
	axios.create({
		baseURL: baseURL,
		headers: {
			"Content-Type": "application/json",
			...headers,
		},
	});

// Use for regular WP REST routes
const wp_base = instance({
	baseURL: window?.global_vars?.rest_base,
});

const wp_acf = instance({
	baseURL: "/wp-json/acf/v3",
});

const job_board = instance({
	baseURL: "/wp-json/jobs/v1",
});

const wp_dist = instance({
	baseURL: "/wp-json/locations/v1",
});

export { wp_base, wp_acf, wp_dist, job_board };
